.ActionsWrapper {
  display: flex;
  flex-direction: row;
}

.Icon {
  margin-bottom: 36px;
  margin-top: 40px;

  svg {
    width: 96px;
    height: 94px;
  }
}

.Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
}
