@import '~styles/base.scss';

.ImportSparkle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid $of-plum-02;
  background-color: $of-plum-05;
  padding: 2px 7px;
  width: min-content;
  height: min-content;
  max-height: 20px;

  small {
    font-size: 12px;
    color: $of-forest-green-01;
    width: max-content;
    line-height: 1.15;
  }
}

.SparkleIcon {
  color: $of-forest-green-01;
  margin-right: 2px;
}
